// Variables

//
// custom-variables.scss
//
$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-xl: 0.5rem !default;
$border-radius-xxl: 0.75rem !default;
$border-radius-xxxl: 1rem !default;
// Shadow
$shadow-sm: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
$shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
$shadow-lg: 0 0 45px 0 rgba(0, 0, 0, 0.12);

//Background left-sidebar
$bg-leftbar: #ffffff;
$bg-leftbar-dark: #38414a;

// Leftbar width
$leftbar-width: 240px;
$leftbar-width-collapsed: 70px;

// Small Leftbar Width
$leftbar-width-sm: 160px;

// layout width
$layout-width: 100%;
// Boxed layout width
$boxed-layout-width: 1300px;

// Dark leftbar menu color
$menu-item-color-dark: #9097a7;
$menu-item-hover-color-dark: #c8cddc;
$menu-item-active-color-dark: #ffffff;

//Menu item colors
$menu-item: #6e768e;
$menu-item-hover: #00acc1;
$menu-item-active: #00acc1;
$menu-sub-item-active: #00acc1;

//Rightbar Width
$rightbar-width: 260px;

//Topbar Background
$bg-topbar-light: #ffffff;
$bg-topbar-dark: #38414a;
$bg-topbar-gradient: linear-gradient(135deg, #6658dd 0, #764ba2 100%);

// Topbar Height
$topbar-height: 50px;

// Secondary font
$font-family-secondary: "Cerebri Sans,sans-serif";

// Font weight
$font-weight-medium: 500;
$font-weight-semibold: 600;

// Dropdown Large
$dropdown-lg-width: 320px;

//
// Color system
//

// stylelint-disable
$gray-100: #f1f5f7;
$gray-200: #f7f7f7;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #98a6ad;
$gray-700: #485561;
$gray-800: #36404a;
$gray-900: #323a46;

$grays: ();
$grays: map-merge(
    (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900,
    ),
    $grays
);

// Pom Color Theme Map

$mastercolor-one: #f9de5d;
$mastercolor-two: #f7b720;


$mastercolor-50: #FDF0D1;
$mastercolor-100: #FDEABE;
$mastercolor-200: #FBDD96;
$mastercolor-300: #FAD06F;
$mastercolor-400: #F8C447;
$mastercolor-500: #F7B720;
$mastercolor-600: #D79908;
$mastercolor-700: #A17306;
$mastercolor-800: #6b4c04;
$mastercolor-900: #352602;


// stylelint-disable
$reds-50: #ffb5aa;
$reds-100: #ff9f90;
$reds-200: #ff735d;
$reds-300: #ff472a;
$reds-400: #f62200;
$reds-500: #c31b00;
$reds-600: #901400;
$reds-700: #5d0d00;
$reds-800: #2a0600;
$reds-900: #000000;

$reds: ();
$reds: map-merge(
    (
        "50": $reds-50,
        "100": $reds-100,
        "200": $reds-200,
        "300": $reds-300,
        "400": $reds-400,
        "500": $reds-500,
        "600": $reds-600,
        "700": $reds-700,
        "800": $reds-800,
        "900": $reds-900,
    ),
    $reds
);

// $blue:       #4a81d4;
// $indigo:     #675aa9;
// $purple:     #6658dd;
// $pink:       #f672a7;
// $red:        #f1556c;
// $orange:     #fd7e14;
// $yellow:     #f7b84b;
// $green:      #1abc9c;
// $teal:       #02a8b5;
// $cyan:       #4fc6e1;

$colors: ();
$colors: map-merge(
    (
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800,
    ),
    $colors
);

$primary: $blue;
$secondary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$blue: $blue;
$light: $gray-100;
$dark: $gray-300;

$theme-colors: ();
$theme-colors: map-merge(
    (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
        "pink": $pink,
        "blue": $blue,
    ),
    $theme-colors
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 180;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-800;
$yiq-text-light: $white;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.5rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
    ),
    $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
    (
        15: 15%,
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
    ),
    $sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $gray-500;

// Links
//
// Style anchor elements.

$link-color: $primary;
$link-hover-color: lighten($link-color, 15%);
$link-hover-decoration: none;

// Components
//
$component-active-bg: $primary;

$caret-width: 0.25em;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 24px;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Nunito", sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

// stylelint-enable value-keyword-case
$font-size-base: 0.875rem;
$font-weight-bold: 700;

$headings-margin-bottom: $spacer;
$headings-font-weight: 400;
$headings-line-height: 1.1;

$h1-font-size: 2.25rem;
$h2-font-size: 1.875rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.125rem;
$h5-font-size: 0.9375rem;
$h6-font-size: 0.75rem;

$display-line-height: $headings-line-height;

$lead-font-size: ($font-size-base * 1.25);

$small-font-size: 0.75rem;

$text-muted: $gray-600;

$blockquote-font-size: ($font-size-base * 1.25);

$hr-border-color: lighten($gray-700, 3%);

$list-inline-padding: 6px;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.3rem !default;

$table-color: $body-color !default;
$table-bg: null !default;
$table-accent-bg: rgba($black, 0.05) !default;
$table-hover-color: $table-color !default;
$table-hover-bg: rgba($black, 0.075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;
$table-th-font-weight: null !default;

$table-dark-color: $white !default;
$table-dark-bg: $gray-800 !default;
$table-dark-accent-bg: rgba($white, 0.05) !default;
$table-dark-hover-color: $table-dark-color !default;
$table-dark-hover-bg: rgba($white, 0.075) !default;
$table-dark-border-color: lighten($table-dark-bg, 7.5%) !default;

$table-striped-order: odd !default;

$table-caption-color: $text-muted !default;

$table-bg-level: -9 !default;
$table-border-level: -6 !default;
// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.45rem;
$input-btn-padding-x: 0.9rem;
$input-btn-font-size: $font-size-base;

$input-btn-focus-width: 0.15rem;
$input-btn-focus-color: rgba($primary, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.28rem;
$input-btn-padding-x-sm: 0.8rem;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-size: $input-btn-font-size;

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-font-weight: $font-weight-normal;
$btn-focus-width: 0.15rem;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;

$btn-link-disabled-color: $gray-500;

// Allows for customizing button radius independently from global border radius

$btn-border-radius: 0.15rem;
$btn-border-radius-lg: 0.15rem;
$btn-border-radius-sm: 0.15rem;

// Forms

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-size: $input-btn-font-size;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-bg: $white;
$input-disabled-bg: lighten($gray-800, 5%);

$input-color: $gray-900;
$input-border-width: 2px;
// $input-border-color: lighten($gray-800, 7%);
$input-border-color: lighten($reds-600, 5%);
$input-border-radius: $border-radius-xxl;

$input-focus-box-shadow: none;
$input-focus-bg: lighten($gray-200, 5%);
$input-focus-border-color: lighten($input-border-color, 3%);
$input-focus-color: $black;

$input-placeholder-color: $gray-500;
$input-plaintext-color: $gray-400;

$input-height: calc(
    #{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
);
$input-height-inner: calc(
    #{$input-line-height * 1em} + #{$input-padding-y * 2}
);

$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-700;
$input-group-addon-border-color: $input-border-color;

$custom-select-padding-y: 0.45rem;
$custom-select-padding-x: 0.9rem;
$custom-select-font-size: $input-font-size;
$custom-select-height: $input-height;

$custom-control-indicator-bg: $gray-700;
$custom-control-indicator-checked-color: $primary;
$custom-control-indicator-border-color: $gray-700;
$custom-control-indicator-checked-bg: $primary;

$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-checked-disabled-bg: rgba(
    theme-color("primary"),
    0.5
);
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-select-border-width: $input-border-width;
$custom-select-color: $input-color;
$custom-select-bg: $input-bg;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-box-shadow: none;
$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg,
    $input-btn-focus-box-shadow;
$custom-select-indicator-color: $gray-500;
$custom-select-indicator: str-replace(
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"),
    "#",
    "%23"
);
$custom-select-background: $custom-select-indicator no-repeat right
    $custom-select-padding-x center / $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)
$custom-range-track-width: 100%;
$custom-range-track-height: 0.5rem;
$custom-range-track-cursor: pointer;
$custom-range-track-bg: $gray-700;
$custom-range-track-border-radius: 1rem;
$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);

$custom-range-thumb-width: 1rem;
$custom-range-thumb-height: $custom-range-thumb-width;
$custom-range-thumb-bg: $component-active-bg;
$custom-range-thumb-border: 0;
$custom-range-thumb-border-radius: 1rem;
$custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg,
    $input-btn-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width: $input-btn-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%);

$custom-file-height: $input-height;
$custom-file-height-inner: $input-height-inner;
$custom-file-focus-border-color: $input-focus-border-color;
$custom-file-focus-box-shadow: none;
$custom-file-height-inner: $input-height-inner;

$custom-file-padding-y: $input-btn-padding-y;
$custom-file-padding-x: $input-btn-padding-x;
$custom-file-line-height: $input-btn-line-height;
$custom-file-color: $input-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: none;
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $input-group-addon-bg;
$custom-file-text: (
    en: "Browse",
) !default;

// Form validation

$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $small-font-size;
$form-feedback-valid-color: theme-color("success");
$form-feedback-invalid-color: theme-color("danger");

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: str-replace(
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"),
    "#",
    "%23"
);
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: str-replace(
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"),
    "#",
    "%23"
);

$form-validation-states: ();
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
    (
        "valid": (
            "color": $form-feedback-valid-color,
            "icon": $form-feedback-icon-valid,
        ),
        "invalid": (
            "color": $form-feedback-invalid-color,
            "icon": $form-feedback-icon-invalid,
        ),
    ),
    $form-validation-states
);

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-bg: lighten($gray-800, 5%);

$dropdown-padding-y: 0.25rem;
$dropdown-border-color: lighten($gray-800, 8%);

$dropdown-color: $body-color;
$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $body-color;
$dropdown-link-hover-bg: $gray-700;

$dropdown-link-active-color: $dark;
$dropdown-link-active-bg: $gray-700;

$dropdown-item-padding-y: 0.375rem;
$dropdown-item-padding-x: 1.2rem;

$dropdown-header-color: inherit;

// Navs
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$nav-tabs-link-active-color: $gray-400;
$nav-tabs-link-active-bg: $gray-700;
$nav-tabs-border-color: $gray-700;
$nav-tabs-link-active-border-color: $gray-700;
$nav-tabs-link-hover-border-color: $gray-700;
$nav-divider-color: $gray-600;

// Pagination

$pagination-bg: $gray-700;
$pagination-border-color: lighten($gray-700, 4%);
$pagination-color: $dark;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;

$pagination-hover-color: $dark;
$pagination-hover-bg: lighten($gray-700, 5%);
$pagination-hover-border-color: lighten($gray-700, 7%);
$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: lighten($gray-700, 5%);
$pagination-disabled-border-color: lighten($gray-700, 7%);
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

// Jumbotron

$jumbotron-bg: $gray-700;

// Cards

$card-spacer-x: $spacer;
$card-spacer-y: $spacer * 2/3;
$card-border-width: 0;
$card-border-color: $gray-200;
$card-border-radius: 0.25rem;
$card-bg: $gray-800;
$card-cap-bg: $gray-700;
$card-columns-margin: $grid-gutter-width;
$card-columns-gap: $grid-gutter-width;

// Tooltips

$tooltip-font-size: $font-size-base;

$tooltip-border-radius: 0.2rem;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.8rem;
$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $gray-900;
$tooltip-bg: $gray-100;
$tooltip-arrow-color: $tooltip-bg;

// Popovers

$popover-border-color: $gray-400;
$popover-header-bg: $gray-100;
$popover-header-color: $gray-700;
$popover-border-color: $gray-300;
$popover-border-radius: $border-radius;
$popover-header-padding-y: 0.7rem;
$popover-header-padding-x: 0.8rem;

// Badges

$badge-font-weight: $font-weight-bold;

// Modals
$modal-content-border-width: 0;
$modal-content-border-color: transparent;
$modal-content-border-radius: 0.2rem;
$modal-backdrop-bg: $gray-800;
// $modal-content-bg: $gray-700;
$modal-content-bg: $white;
$modal-header-border-color: lighten($reds-700, 5%);
$modal-footer-border-color: lighten($reds-700, 5%);
$modal-content-border-width: 2px;
$modal-content-border-color: lighten($reds-600, 5%);
$modal-content-border-radius: $border-radius-xxl !default;

// $border-radius-lg
// Progress bars

$progress-bg: $gray-700;
$progress-height: 0.75rem;
$progress-bar-bg: theme-color("primary");

// List group

$list-group-bg: $gray-800;
$list-group-border-color: $gray-700;
$list-group-active-bg: $component-active-bg;

// Image thumbnails

$thumbnail-bg: $gray-700;
$thumbnail-border-color: lighten($gray-700, 3%);

// Breadcrumbs

$breadcrumb-padding-y: $spacer/1.5;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-bg: transparent;
$breadcrumb-divider: quote("\F142");
$breadcrumb-divider-color: $gray-400;
$breadcrumb-active-color: $gray-500;

// Close
$close-font-size: 1.4rem;
$close-text-shadow: none;
$close-color: $gray-400;

// Code

$code-color: $pink;
