// Font Size List
.text-xs {
    font-size: 0.75rem !important;
    line-height: 1rem;
}
.text-sm {
    font-size: 0.875rem !important;
    line-height: 1.25rem;
}
.text-base {
    font-size: 1rem !important;
    line-height: 1.5rem;
}
.text-lg {
    font-size: 1.125rem !important;
}
.text-lg,
.text-xl {
    line-height: 1.75rem;
}
.text-xl {
    font-size: 1.25rem !important;
}
.text-2xl {
    font-size: 1.5rem !important;
    line-height: 2rem;
}
.text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem;
}
.text-4xl {
    font-size: 2.25rem !important;
    line-height: 2.5rem;
}
.text-5xl {
    font-size: 3rem !important;
    line-height: 1;
}
.text-6xl {
    font-size: 3.75rem !important;
    line-height: 1;
}
.text-7xl {
    font-size: 4.5rem !important;
    line-height: 1;
}
.text-8xl {
    font-size: 6rem !important;
    line-height: 1;
}
.text-9xl {
    font-size: 8rem !important;
    line-height: 1;
}

.nav-bar-gradient {
    background-image: linear-gradient(to right, $mastercolor-one, $mastercolor-two);
}

.nav-bar-divide-x {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));

    --tw-divide-opacity: 1;
    border-color: $white;
}

.text-yellow {
    color: $yellow;
}
.min-h-screen {
    min-height: 100vh;
}
.bg-red-two {
    background-color: $mastercolor-two;
}
.bg-orange{
    background-color: #ef6c00;
}
.border-2 {
    border: 2px solid ;
}
.border-red-two {
    border-color: $mastercolor-two !important;
}
.border-orange {
    border-color: #ef6c00 !important;
}
.border-green{
    border-color: #047857 !important;
}
.border-brown{
    border-color: #78350f !important;
}
body {
    overflow-x: hidden;
}

.text-gray-100 {
    color: $gray-100;
}
.text-gray-200 {
    color: $gray-200;
}
.text-gray-300 {
    color: $gray-300;
}
.text-gray-400 {
    color: $gray-400;
}
.text-gray-500 {
    color: $gray-500;
}
.text-gray-600 {
    color: $gray-600;
}
.text-gray-700 {
    color: $gray-700;
}
.text-gray-800 {
    color: $gray-800;
}
.text-gray-900 {
    color: $gray-900;
}

.text-black {
    color: $black;
}
.text-red-50 {
    color: $reds-50;
}
.text-red-100 {
    color: $reds-100;
}
.text-red-200 {
    color: $reds-200;
}
.text-red-300 {
    color: $reds-300;
}
.text-red-400 {
    color: $reds-400;
}
.text-red-500 {
    color: $reds-500;
}
.text-red-600 {
    color: $reds-600;
}
.text-red-700 {
    color: $reds-700;
}
.text-red-800 {
    color: $reds-800;
}
.text-red-900 {
    color: $reds-900;
}

.hover-transition{
    @apply transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-105;
}



.offcanvas-menu .site-wrap {
    position: absolute;
    overflow: hidden;
}

.site-wrap:before {
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out;
    background: rgba(0, 0, 0, 0.6);
    content: "";
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
}

.offcanvas-menu .site-wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    overflow: hidden;
}

.offcanvas-menu .site-wrap:before {
    opacity: 1;
    visibility: visible;
}

/* Navbar */
.site-navbar {
    margin-bottom: 0px;
    z-index: 1999;
    position: relative;
    width: 100%;
}

.site-navbar.transparent {
    background: transparent;
}

.site-navbar.absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.site-navbar .site-logo {
    position: relative;
    left: 0;
    top: -5px;
}

.site-navbar .site-navigation.border-bottom {
    border-bottom: 1px solid #f3f3f4 !important;
}

.site-navbar .site-navigation .site-menu {
    margin-bottom: 0;
}

.site-navbar .site-navigation .site-menu .active>a {
    color: #0d0cb5;
    display: inline-block;
    padding: 5px 20px;
}

.site-navbar .site-navigation .site-menu a {
    text-decoration: none !important;
    display: inline-block;
}

.site-navbar .site-navigation .site-menu>li {
    display: inline-block;
}

.site-navbar .site-navigation .site-menu>li>a {
    padding: 5px 20px;
    color: #000;
    display: inline-block;
    text-decoration: none !important;
}

.site-navbar .site-navigation .site-menu>li>a:hover {
    color: #0d0cb5;
}

.site-navbar .site-navigation .site-menu .has-children {
    position: relative;
}

.site-navbar .site-navigation .site-menu .has-children>a {
    position: relative;
    padding-right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children>a:before {
    position: absolute;
    content: "\e313";
    font-size: 16px;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: 'icomoon';
}

.site-navbar .site-navigation .site-menu .has-children .dropdown {
    visibility: hidden;
    opacity: 0;
    top: 100%;
    position: absolute;
    text-align: left;
    border-top: 2px solid #0d0cb5;
    -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
    border-left: 1px solid #edf0f5;
    border-right: 1px solid #edf0f5;
    border-bottom: 1px solid #edf0f5;
    padding: 0px 0;
    margin-top: 20px;
    margin-left: 0px;
    background: #fff;
    -webkit-transition: 0.2s 0s;
    -o-transition: 0.2s 0s;
    transition: 0.2s 0s;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
    position: absolute;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
    bottom: 100%;
    left: 20%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown a {
    text-transform: none;
    letter-spacing: normal;
    -webkit-transition: 0s all;
    -o-transition: 0s all;
    transition: 0s all;
    color: #343a40;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown .active>a {
    color: #0d0cb5 !important;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 200px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li>a {
    padding: 9px 20px;
    display: block;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li>a:hover {
    background: #f4f5f9;
    color: #25262a;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>a:before {
    content: "\e315";
    right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>.dropdown,
.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>ul {
    left: 100%;
    top: 0;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:hover>a,
.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:active>a,
.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:focus>a {
    background: #f4f5f9;
    color: #25262a;
}

.site-navbar .site-navigation .site-menu .has-children:hover>a,
.site-navbar .site-navigation .site-menu .has-children:focus>a,
.site-navbar .site-navigation .site-menu .has-children:active>a {
    color: #0d0cb5;
}

.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
    cursor: pointer;
}

.site-navbar .site-navigation .site-menu .has-children:hover>.dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus>.dropdown,
.site-navbar .site-navigation .site-menu .has-children:active>.dropdown {
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
}

.site-mobile-menu {
    width: 300px;
    position: fixed;
    right: 0;
    z-index: 2000;
    padding-top: 20px;
    background: #fff;
    height: calc(100vh);
    -webkit-transform: translateX(110%);
    -ms-transform: translateX(110%);
    transform: translateX(110%);
    -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
    box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out;
}

.offcanvas-menu .site-mobile-menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}

.site-mobile-menu .site-mobile-menu-header {
    width: 100%;
    float: left;
    padding-left: 20px;
    padding-right: 20px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
    float: right;
    margin-top: 8px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
    font-size: 30px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 0px;
    line-height: 1;
    cursor: pointer;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
    color: #25262a;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
    float: left;
    margin-top: 10px;
    margin-left: 0px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
    display: inline-block;
    text-transform: uppercase;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
    max-width: 70px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
    text-decoration: none;
}

.site-mobile-menu .site-mobile-menu-body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 0 20px 20px 20px;
    height: calc(100vh - 52px);
    padding-bottom: 150px;
}

.site-mobile-menu .site-nav-wrap {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
}

.site-mobile-menu .site-nav-wrap a {
    padding: 10px 20px;
    display: block;
    position: relative;
    color: #212529;
}

.site-mobile-menu .site-nav-wrap a:hover {
    color: #0d0cb5;
}

.site-mobile-menu .site-nav-wrap li {
    position: relative;
    display: block;
}

.site-mobile-menu .site-nav-wrap li.active>a {
    color: #0d0cb5;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse {
    position: absolute;
    right: 0px;
    top: 10px;
    z-index: 20;
    width: 36px;
    height: 36px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
    background: #f8f9fa;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
    font-size: 12px;
    z-index: 20;
    font-family: "icomoon";
    content: "\f078";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(-180deg);
    -ms-transform: translate(-50%, -50%) rotate(-180deg);
    transform: translate(-50%, -50%) rotate(-180deg);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.site-mobile-menu .site-nav-wrap>li {
    display: block;
    position: relative;
    float: left;
    width: 100%;
}

.site-mobile-menu .site-nav-wrap>li>a {
    padding-left: 20px;
    font-size: 20px;
}

.site-mobile-menu .site-nav-wrap>li>ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.site-mobile-menu .site-nav-wrap>li>ul>li {
    display: block;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>a {
    padding-left: 40px;
    font-size: 16px;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>ul {
    padding: 0;
    margin: 0;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>ul>li {
    display: block;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>ul>li>a {
    font-size: 16px;
    padding-left: 60px;
}

.site-mobile-menu .site-nav-wrap[data-class="social"] {
    float: left;
    width: 100%;
    margin-top: 30px;
    padding-bottom: 5em;
}

.site-mobile-menu .site-nav-wrap[data-class="social"]>li {
    width: auto;
}

.site-mobile-menu .site-nav-wrap[data-class="social"]>li:first-child a {
    padding-left: 15px !important;
}

.rounded-full{
    border-radius: 9999px;
}
