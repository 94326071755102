@tailwind base;
@tailwind components;
@tailwind utilities;

@import "plugin/intro";
// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");
// Variables
//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
// ------------------------------
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";
@import "AdminUbold/main.scss";
// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Custom
@import "custom";

// @import "./node_modules/lightgallery/scss/lightgallery.scss";